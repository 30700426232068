import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout.jsx"
import Hero from "../components/Hero.jsx"
import Seo from "../components/Seo"
import Vision from "../assets/images/icons/vision.png"
import Mission from "../assets/images/icons/mission.png"
import Value from "../assets/images/icons/value.png"
import Banner from "../assets/images/banner.png"
import Check from "../assets/images/icons/check.png"
import FirstAuthor from "../assets/images/comment1.png"
import SecondAuthor from "../assets/images/comment2.png"
import ThirdAuthor from "../assets/images/comment3.png"
import IconScroll from "../assets/images/icons/scroll-top-icon.svg"

import "../styles/pages/About.scss"

export default function About() {
  const intl = useIntl()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "about_title_page" })} />
      <Hero
        title={intl.formatMessage({ id: "about_title_page" })}
        text={intl.formatMessage({ id: "about_subtitle" })}
      />
      <section className="mission">
        <div className="mission__container">
          <article className="mission__box">
            <img src={Mission} alt="mission" className="mission__icon" />
            <h5 className="mission__name">
              {intl.formatMessage({ id: "about_mission_title" })}
            </h5>
            <p className="mission__text">
              {intl.formatMessage({ id: "about_mission_txt" })}
            </p>
          </article>
          <article className="mission__box">
            <img src={Vision} alt="Vision" className="mission__icon" />
            <h5 className="mission__name">
              {intl.formatMessage({ id: "about_vision_title" })}
            </h5>
            <p className="mission__text">
              {intl.formatMessage({ id: "about_vision_txt" })}
            </p>
          </article>
          <article className="mission__box">
            <img src={Value} alt="Valor" className="mission__icon" />
            <h5 className="mission__name">
              {intl.formatMessage({ id: "about_values_title" })}
            </h5>
            <p className="mission__text">
              {intl.formatMessage({ id: "about_values_txt" })}
            </p>
          </article>
        </div>
      </section>
      <section className="banner">
        <div className="banner__container">
          <div className="banner__box">
            <img src={Banner} alt="Banner" className="banner__image" />
          </div>
          <div className="banner__box banner__box--text">
            <h3 className="banner__title">
              {intl.formatMessage({ id: "about_banner_title" })}
            </h3>
            <ul className="banner__list">
              <li className="banner__item">
                {intl.formatMessage({ id: "about_banner_list_one" })}
              </li>
              <li className="banner__item">
                {intl.formatMessage({ id: "about_banner_list_two" })}
              </li>
              <li className="banner__item">
                {intl.formatMessage({ id: "about_banner_list_three" })}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="points">
        <div className="points__container">
          <ul className="points__list">
            <li className="points__item">
              <img src={Check} alt="Points" className="points__icon" />
              <p className="points__text">
                {intl.formatMessage({ id: "about_check_one" })}
              </p>
            </li>
            <li className="points__item">
              <img src={Check} alt="Points" className="points__icon" />
              <p className="points__text">
                {intl.formatMessage({ id: "about_check_two" })}
              </p>
            </li>
            <li className="points__item">
              <img src={Check} alt="Points" className="points__icon" />
              <p className="points__text">
                {intl.formatMessage({ id: "about_check_three" })}
              </p>
            </li>
            <li className="points__item">
              <img src={Check} alt="Points" className="points__icon" />
              <p className="points__text">
                {intl.formatMessage({ id: "about_check_four" })}
              </p>
            </li>
            <li className="points__item">
              <img src={Check} alt="Points" className="points__icon" />
              <p className="points__text">
                {intl.formatMessage({ id: "about_check_five" })}
              </p>
            </li>
            <li className="points__item">
              <img src={Check} alt="Points" className="points__icon" />
              <p className="points__text">
                {intl.formatMessage({ id: "about_check_six" })}
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="comments">
        <div className="comments__container">
          <h2 className="comments__title">
            {intl.formatMessage({ id: "about_clients_title" })}
          </h2>
          <div className="comments__grid">
            <article className="comments__item">
              <div className="comments__picture">
                <img
                  src={FirstAuthor}
                  alt="First Author"
                  className="comments__image"
                />
              </div>
              <p className="comments__text">
                {intl.formatMessage({ id: "about_client_one" })}
              </p>
              <span className="comments__author">
                <p className="comments__name">
                  {intl.formatMessage({ id: "about_client_one_name" })}
                </p>
                <p className="comments__profession">
                  {intl.formatMessage({ id: "about_client_one_profession" })}
                </p>
              </span>
            </article>
            <article className="comments__item">
              <div className="comments__picture">
                <img
                  src={SecondAuthor}
                  alt="Second Author"
                  className="comments__image"
                />
              </div>
              <p className="comments__text">
                {intl.formatMessage({ id: "about_client_two" })}
              </p>
              <span className="comments__author">
                <p className="comments__name">
                  {intl.formatMessage({ id: "about_client_two_name" })}
                </p>
                <p className="comments__profession">
                  {intl.formatMessage({ id: "about_client_two_profession" })}
                </p>
              </span>
            </article>
            <article className="comments__item">
              <div className="comments__picture">
                <img
                  src={ThirdAuthor}
                  alt="Third Author"
                  className="comments__image"
                />
              </div>
              <p className="comments__text">
                {intl.formatMessage({ id: "about_client_three" })}
              </p>
              <span className="comments__author">
                <p className="comments__name">
                  {intl.formatMessage({ id: "about_client_three_name" })}
                </p>
                <p className="comments__profession">
                  {intl.formatMessage({ id: "about_client_three_profession" })}
                </p>
              </span>
            </article>
          </div>
        </div>
      </section>
      <button id="scroll-btn" className="up__button" title="Scroll to top">
        <img src={IconScroll} alt="Up" className="up__image" />
      </button>
    </Layout>
  )
}
